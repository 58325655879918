import {getElementOrParentByClass} from '../helpers/dom-helpers'
export default class FAQ {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;
        this.root = el;

        this.items = this.root.querySelectorAll('[data-faq-item]');
        this.answer = this.root.querySelectorAll('[data-faq-item-answer]');
        this.listEl = this.root.querySelector('[data-faq-list]');
        this.moreEl = this.root.querySelector('[data-faq-more]');

        this.settings = JSON.parse(this.root.getAttribute('data-settings'));

        this.currPage = 1;
        this.maxPages = this.settings.maxPages;
        this.faqData = JSON.parse(this.root.getAttribute('data-faq'));

        this.html = this.listEl.innerHTML;

        return true;
    }

    init() {
        this.initItems();

        this.onResize();
        window.addEventListener('resize', this.onResize.bind(this));

        this.setMore();
        this.checkMoreButton();
    }

    initItems() {
        if (!this.items) return;

        this.onItem = this.onItemClick.bind(this);
        this.items.forEach(item => {
            item.addEventListener('click', event => this.onItem(event));
        });
    }

    setMore() {
        if (!this.moreEl) return;

        this.onMore = this.onMoreClick.bind(this);
        this.moreEl.addEventListener('click', this.onMore);
    }

    onItemClick(event) {
        const { currentTarget } = event;

        const answer = currentTarget.querySelector('.faq-block__answer');
        let is_content = getElementOrParentByClass(event, 'wysiwyg-editor')
        if (!is_content) {
            currentTarget.classList.toggle('active');
        }
        if (currentTarget.classList.contains('active')) {
            if (answer) {
                const height = parseInt(answer.getAttribute('max-height'));
                answer.style.maxHeight = `${height + 64}px`;
            }
        } else {
            if (answer) {
                answer.style.maxHeight = `0px`;
            }
        }
    }

    async onMoreClick(event) {
        const { currentTarget } = event;

        currentTarget.classList.add('-loading');

        this.currPage += 1;
        await this.loadFaq();

        currentTarget.classList.remove('-loading');
    }

    onResize() {
        if (!this.answer) return;

        this.answer.forEach(item => {
            const height = item.offsetHeight + 64;
            item.parentNode.setAttribute('max-height', height);
        });
    }

    async loadFaq() {
        const formData = new FormData();
        if (this.faqData) formData.append('faq', JSON.stringify(this.faqData));
        if (this.currPage) formData.append('page', this.currPage);

        const request = await fetch(`${home_url}/wp-json/faq-block/v1/get`, {
            method: 'POST',
            body: formData,
        });

        const res = await request.json();
        const { html } = res;

        this.html = this.html + html;
        this.listEl.innerHTML = this.html;

        this.items = this.root.querySelectorAll('[data-faq-item]');
        this.answer = this.root.querySelectorAll('[data-faq-item-answer]');

        this.initItems();
        this.checkMoreButton();
        this.onResize();
    }

    checkMoreButton() {
        if (this.currPage === this.maxPages || this.maxPages === 0) {
            this.root.classList.add('-no-results');
        } else {
            this.root.classList.remove('-no-results');
        }
    }
}
